import styled from "styled-components"

import { IParadiseUserEvent } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { Routes } from "src/router/routes"
import { TextButton } from "src/ui/Button/TextButton"
import { colorsLegacy } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import CodeIcon from "src/ui/icons/code.svg"
import { JsonTree } from "src/ui/JsonTree/JsonTree"
import { InternalLink } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

export function ParadiseUserEventRow({
  event,
  open,
  onClose,
}: {
  event: IParadiseUserEvent
  open: boolean
  onClose: () => void
}) {
  return (
    <>
      <div>{formatDate({ date: event.created_at })}</div>
      <div>{event.type}</div>
      {event.device_id ? (
        <InternalLink to={Routes.ParadiseDevice.location(event.device_id)}>
          {event.device_id}
        </InternalLink>
      ) : (
        <div></div>
      )}
      {event.home_id ? (
        <InternalLink to={Routes.ParadiseHome.location(event.home_id)}>
          {event.home_id}
        </InternalLink>
      ) : (
        <div></div>
      )}
      <div>
        <RawButton>
          <CodeIcon color="black" width={12} />
        </RawButton>
      </div>

      <MDialog title="Raw data" open={open} onClose={onClose}>
        <JsonTree json={event} />
      </MDialog>
    </>
  )
}

const RawButton = styled(TextButton)`
  background-color: ${colorsLegacy.backgroundGrayV2};
  padding: ${spacing.XS};
  border-radius: 5px;
  transition: all 0.2s;

  &:hover {
    background-color: ${colorsLegacy.backgroundGray};
  }
`
