import { EmptyState } from "src/components/EmptyState"
import { ParadiseDetailsHeading } from "src/components/Paradise/ParadiseDetailsHeading"
import { ParadiseDetailsLayout } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadiseUserActions } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserActions"
import { ParadiseUserApiClients } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserApiClients/ParadiseUserApiClients"
import { ParadiseUserAppInstallations } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserAppInstallations/ParadiseUserAppInstallations"
import { ParadiseUserDevices } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserDevices/ParadiseUserDevices"
import { ParadiseUserEvents } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserEvents/ParadiseUserEvents"
import { ParadiseUserHomes } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserHomes/ParadiseUserHomes"
import { ParadiseUserHomeTokens } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserHomeTokens/ParadiseUserHomeTokens"
import { ParadisUserOrganizations } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserOrganizations/ParadiseUserOrganizations"
import { ParadiseUserOverview } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserOverview/ParadiseUserOverview"
import { LoadingWrapper } from "src/components/Paradise/sharedStyles"
import { useAddRecentlyViewedPage } from "src/components/RecentlyViewed/useAddRecentlyViewedPage"
import { useFetchParadiseUser } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import Svg404 from "src/ui/icons/404.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

export function ParadiseUserDetails({ userId }: { userId: string }) {
  const { t } = useTranslate()

  const fetchedUser = useFetchParadiseUser({
    userId,
  })

  useAddRecentlyViewedPage({
    title: fetchedUser.data?.full_name,
    enitytId: fetchedUser.data?.id,
    type: "User",
    enabled: fetchedUser.isSuccess,
  })

  if (fetchedUser.isError) {
    return (
      <EmptyState
        title={t(langKeys.failed_general_error_title)}
        body={t(langKeys.failed_general_error_body)}
        icon={<Svg404 />}
      />
    )
  }

  if (fetchedUser.isLoading) {
    return (
      <LoadingWrapper>
        <MSkeleton height={40} width="30ch" />
        <MSkeleton height={20} width="40ch" />
      </LoadingWrapper>
    )
  }

  const user = fetchedUser.data

  return (
    <ParadiseDetailsLayout
      meta={<ParadiseDetailsHeading>{user.full_name}</ParadiseDetailsHeading>}
      actions={<ParadiseUserActions user={user} />}
      left={
        <>
          <ParadiseUserOverview user={user} />
          <ParadiseUserAppInstallations
            appInstallations={user.app_installations}
          />
        </>
      }
      banner={
        user.deleted && (
          <MBanner type="error" fullWidth>
            User is deleted
          </MBanner>
        )
      }
      right={
        <Tabs
          labels={[
            {
              id: "general",
              label: "General",
            },
            {
              id: "api_clients",
              label: "API Clients",
            },
            {
              id: "events",
              label: "Events",
            },
          ]}
        >
          <TabPanel>
            <ParadisUserOrganizations userId={user.id} />
            <ParadiseUserHomes userId={user.id} />
            <ParadiseUserDevices userId={user.id} />
            <ParadiseUserHomeTokens userId={user.id} />
          </TabPanel>
          <TabPanel>
            <ParadiseUserApiClients userId={user.id} />
          </TabPanel>
          <TabPanel>
            <ParadiseUserEvents userId={user.id} />,
          </TabPanel>
        </Tabs>
      }
      breadcrumbs={[
        {
          label: "Users",
          to: Routes.ParadiseUsers.location(),
        },
        {
          label: user.id,
          to: Routes.ParadiseUser.location(user.id),
        },
      ]}
      breadcrumbCopyValue={user.id}
    />
  )
}
