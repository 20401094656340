import { useState } from "react"

import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { ParadiseUserEventRow } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserEvents/ParadiseUserEventRow"
import { useFetchParadiseUserEvents } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"

const LIMIT = 15

export function ParadiseUserEvents({ userId }: { userId: string }) {
  const [offset, setOffset] = useState(0)
  const [selectedUserEventIndex, setSelectedUserEventIndex] = useState<
    number | null
  >(null)

  const fetchedUserEvents = useFetchParadiseUserEvents({
    userId,
    filter: {
      limit: LIMIT,
      offset,
      sort: "created_at",
      sort_by: "desc",
    },
    options: {
      keepPreviousData: true,
    },
  })

  const headers = [
    <div key={"time"}>Time</div>,
    <div key={"type"}>Type</div>,
    <div key={"device-id"}>Device id</div>,
    <div key={"home-id"}>Home id</div>,
    <div key={"misc"}></div>,
  ]

  const rows =
    fetchedUserEvents.data?.events.map((e, i) => (
      <ParadiseUserEventRow
        key={e.event_id}
        event={e}
        open={selectedUserEventIndex === i}
        onClose={() => setSelectedUserEventIndex(null)}
      />
    )) ?? []

  return (
    <div>
      <ParadiseTable
        rows={rows}
        header={headers}
        error={{
          hasError: fetchedUserEvents.isError,
          title: fetchedUserEvents.error?.message,
        }}
        onRowClick={(index) => setSelectedUserEventIndex(index)}
        templateColumns="1fr 1fr 1fr 1fr auto"
      />
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedUserEvents.data?.total_count}
      />
    </div>
  )
}
