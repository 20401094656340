import { useState } from "react"

import { Checkbox, FormControlLabel } from "@material-ui/core"

import { useDeleteUserHomeToken } from "src/data/homes/queries/homeTokenQueries"
import { MDialog } from "src/ui/Dialog/MDialog"

export function ParadiseUserDeleteHomeTokenDialog({
  userId,
  open,
  onClose,
  tokenId,
  payedAmount,
}: {
  userId: string
  open: boolean
  onClose: () => void
  tokenId: string
  payedAmount: string
}) {
  const [shouldRefundUser, setShouldRefundUser] = useState(false)

  const deleteUserHomeToken = useDeleteUserHomeToken()

  function handleDelete() {
    deleteUserHomeToken.mutate({
      userId,
      tokenId,
      create_refund: shouldRefundUser,
    })
  }

  return (
    <MDialog
      title="Delete home token"
      description={tokenId}
      open={open}
      onClose={onClose}
      onConfirm={() => {
        handleDelete()
        onClose()
      }}
      confirmLabel="Delete"
      error={shouldRefundUser && `The user will be refunded: ${payedAmount}`}
    >
      <FormControlLabel
        label="Refund user for purchase"
        control={
          <Checkbox
            checked={shouldRefundUser}
            onChange={(e, checked) => setShouldRefundUser(checked)}
          />
        }
      />
    </MDialog>
  )
}
