import { useState } from "react"

import { ParadiseDetailsSection } from "src/components/Paradise/ParadiseDetatilsLayout"
import { ParadisePager } from "src/components/Paradise/ParadisePager"
import { ParadiseTable } from "src/components/Paradise/ParadiseTable"
import { ParadiseUserHomesFilters } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserHomes/ParadiseUserHomesFilters"
import { IdWrapper } from "src/components/Paradise/sharedStyles"
import { parseParadiseHomeSortField } from "src/data/paradise/paradiseHomes/logic/paradiseHome"
import { useFetchParadiseHomes } from "src/data/paradise/paradiseHomes/queries/paradiseHomeQueries"
import { IParadiseHome } from "src/data/paradise/paradiseHomes/types/paradiseHomeQuerytypes"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { useSearchParams } from "src/router/useSearchParams"
import { mColors } from "src/ui/colors"
import { useSortParam } from "src/ui/GridTable/useTableColumns/useSortParam"
import {
  TableColumn,
  useTableColumns,
} from "src/ui/GridTable/useTableColumns/useTableColumns"
import InfoIcon from "src/ui/icons/info.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { formatDate } from "src/utils/l10n"

const LIMIT = 15

const tableColumns: TableColumn<IParadiseHome>[] = [
  {
    value: "home_id",
    label: "Home id",
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (home) => {
      return (
        <IdWrapper>
          <InternalLink to={Routes.ParadiseHome.location(home.id)}>
            {home.id}
          </InternalLink>
          {home.deleted && (
            <MTooltip title="Deleted">
              <InfoIcon width={12} color={mColors.systemErrorDark} />
            </MTooltip>
          )}
        </IdWrapper>
      )
    },
  },
  {
    value: "name",
    label: "Name",
    columnWidth: "min-content",
    render: (home) => {
      return <div>{home.name}</div>
    },
  },
  {
    value: "createdAt",
    label: "Created at",
    columnWidth: "min-content",
    enableSort: true,
    render: (home) => {
      return <div>{formatDate({ date: home.created_at, timezone: "utc" })}</div>
    },
  },
  {
    value: "created_by",
    label: "Created by",
    columnWidth: "min-content",
    disableClickPropagation: true,
    render: (home) => {
      return (
        <InternalLink to={Routes.ParadiseUser.location(home.created_by)}>
          {home.created_by}
        </InternalLink>
      )
    },
  },
  {
    value: "deletedAt",
    label: "Deleted at",
    enableSort: true,
    columnWidth: "auto",
    render: (home) => {
      return (
        <div>
          {home.deleted_at
            ? formatDate({ date: home.deleted_at, timezone: "utc" })
            : "-"}
        </div>
      )
    },
  },
]

export function ParadiseUserHomes({ userId }: { userId: string }) {
  const { navigate } = useRouter()

  const [offset, setOffset] = useState(0)
  const { searchParams: filter, setSearchParams: setFilter } = useSearchParams({
    keys: [
      {
        key: "homes_showDeletedHomes",
        type: "boolean",
      },
      {
        key: "homes_name",
        type: "string",
      },
    ],
  })

  const { sortValue, setSortValue } = useSortParam()

  const fetchedHomes = useFetchParadiseHomes({
    filter: {
      user_id: userId,
      limit: LIMIT,
      offset,
      deleted: !!filter.homes_showDeletedHomes,
      name: filter.homes_name ?? undefined,
      sort: parseParadiseHomeSortField(sortValue?.id)
        ? sortValue?.id
        : undefined,
      sort_by: sortValue?.order,
    },
    options: {
      select: (data) => {
        return {
          homes: data.homes.slice(offset, offset + LIMIT),
          total_count: data.homes.length,
        }
      },
      keepPreviousData: true,
    },
  })

  const { headerElements, rows, templateColumns } = useTableColumns({
    columns: tableColumns,
    data: fetchedHomes.data?.homes,
    sort: sortValue,
    onSortChange: setSortValue,
  })

  return (
    <div>
      <ParadiseDetailsSection title="Homes" />
      <ParadiseUserHomesFilters filter={filter} setFilter={setFilter} />
      <ParadiseTable
        header={headerElements}
        rows={rows ?? []}
        templateColumns={templateColumns}
        onRowClick={(index) => {
          if (fetchedHomes.data?.homes) {
            navigate(
              Routes.ParadiseHome.location(
                // @ts-expect-error: noUncheckedIndexedAccess
                fetchedHomes.data.homes[index].home_id
              )
            )
          }
        }}
        error={{
          hasError: fetchedHomes.isError,
          title: fetchedHomes.error?.message,
        }}
      />
      <ParadisePager
        limit={LIMIT}
        offset={offset}
        setOffset={setOffset}
        totalCount={fetchedHomes.data?.total_count}
      />
    </div>
  )
}
